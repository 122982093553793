import {
  Button,
  Card,
  CardActions,
  CardContent,
  Stack,
  Typography,
} from '@mui/material'
import { useAuthentication, useEpiContent } from '@trr/app-shell-data'
import { Content } from 'types'
import { useGetCommingPaymentsQuery } from 'api'
import { date } from '@trr/frontend-datetime'

const Payments = () => {
  const content = useEpiContent<Content>()
  const { sub } = useAuthentication()
  const { data: payments } = useGetCommingPaymentsQuery(
    { sub, paymentTypes: content.paymentTypes },
    { skip: sub === null }
  )
  const payment = payments?.[0]
  return (
    <Card
      data-testid="paymentCard"
      sx={{ backgroundColor: 'primary.main', color: '#FFFFFF' }}
    >
      <CardContent>
        <Typography variant="subtitle2" component={'h2'} gutterBottom>
          {content.heading}
        </Typography>
        <Stack
          mr={5}
          justifyContent={'space-between'}
          direction={{ xs: 'column', sm: 'row' }}
        >
          <Typography
            mb={{ xs: 2, sm: 'initial' }}
            flexBasis={'55%'}
            variant="h2"
            component="span"
            data-testid="paymentCash"
          >
            {payment?.nettobelopp.toLocaleString('sv-SE', {
              style: 'currency',
              currency: 'SEK',
              maximumFractionDigits: 0,
            })}
          </Typography>
          <Stack
            columnGap={{ xs: 6, sm: 3 }}
            flexGrow={1}
            justifyContent={{ xs: 'unset', sm: 'space-between' }}
            direction={'row'}
          >
            <Stack data-testid="paymentDate">
              <Typography variant="body1" component="p">
                {content.dateHeading}
              </Typography>
              <Typography variant="caption">
                {payment?.planeratUtbetalningsdatum &&
                  date.format(payment.planeratUtbetalningsdatum, {
                    year: true,
                  })}
              </Typography>
            </Stack>
            <Stack data-testid="paymentType">
              <Typography variant="body1" component="p">
                {content.paymentTypeHeading}
              </Typography>
              <Typography variant="caption">{payment?.typ}</Typography>
            </Stack>
          </Stack>
        </Stack>
      </CardContent>
      <CardActions>
        <Button
          data-testid="paymentAllLink"
          href={content.linkUrl}
          size="small"
          variant="text"
          sx={{ color: '#FFFFFF' }}
        >
          {content.linkText}
        </Button>
      </CardActions>
    </Card>
  )
}

export default Payments
