import { useAuthentication, useEpiContent } from '@trr/app-shell-data'
import { useGetCommingPaymentsQuery } from 'api'
import { Payments } from 'features'

import { Content } from 'types'

export const App = () => {
  const { sub } = useAuthentication()
  const content = useEpiContent<Content>()
  const {
    data: payments,
    isLoading: paymentsLoading,
    error: paymentsError,
  } = useGetCommingPaymentsQuery(
    { sub, paymentTypes: content.paymentTypes },
    { skip: sub === null }
  )
  return paymentsLoading || paymentsError || payments?.length === 0 ? null : (
    <Payments />
  )
}
